<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 34.181 26.417"
      :aria-labelledby="iconName"
      role="presentation"
      v-if="!geoFenceMarker"
    >
      <defs>
        <linearGradient
          id="linearGradient1516"
          x1="131.92"
          x2="131.83"
          y1="194.61"
          y2="164.39"
          gradientUnits="userSpaceOnUse"
          xlink:href="#linearGradient1514"
        />
        <linearGradient id="linearGradient1514">
          <stop offset="0" />
          <stop stop-color="#292929" offset="1" />
        </linearGradient>
        <linearGradient
          id="linearGradient1518"
          x1="736.25"
          x2="737.97"
          y1="760.9"
          y2="654.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" />
          <stop stop-color="#000004" stop-opacity="0" offset="1" />
        </linearGradient>
      </defs>

      <g
        transform="matrix(.97397 .23901 -.731 .31845 27.468 -68.258)"
        fill="#C4C4C4"
        opacity=".3"
      >
        <g transform="translate(-62.407,-5.6127)" fill="#C4C4C4" stroke="#fff">
          <path
            transform="scale(.26458)"
            d="m733.81 675.83a38.278 38.278 0 0 0-37.98 38.277 38.278 38.278 0 0 0 27.617 36.764l8.7031 17.057c0.69725 1.3663 2.7729 2.2401 3.916 0l8.7031-17.057a38.278 38.278 0 0 0 27.617-36.764 38.278 38.278 0 0 0-38.277-38.277 38.278 38.278 0 0 0-0.29883 0z"
            fill="C4C4C4"
            fill-rule="evenodd"
            stroke="none"
            stroke-linecap="round"
            stroke-width="6.389"
          />
        </g>
      </g>
      <g :fill="iconColor">
        <slot />
      </g>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 16.532 16.532"
      :aria-labelledby="iconName"
      role="presentation"
      v-if="geoFenceMarker"
    >
      <g :fill="iconColor">
        <slot />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 50,
    },
    height: {
      type: [Number, String],
      default: 50,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    geoFenceMarker: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>